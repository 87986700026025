import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import Button from "../../atoms/button/button"
// import Input from "../../atoms/input/input"
// import InputCheck from "../../atoms/input-check/input-check"
import siteUrl from "../../../utils/siteUrl"
import "./section-contact.scss"

const SectionContact = () => {
    const { contentfulExtranetTransversal } = useStaticQuery(
        graphql`
          query Contact_Query {
              contentfulExtranetTransversal {
                  logo {
                    file {
                        url
                    }
                  }
                  logoEssity {
                    file {
                        url
                    }
                  }
                  informacionSiguenos {
                      raw
                  }
                  informacionContactanos {
                      raw
                  }
                  ctaContactanos
                  informacionBoletin {
                      raw
                  }
                  ctaBoletin
                  ctaRedireccionContactanos
                  redesSociales {
                      redireccionDeLaCategoria
                      nombreDeLaCategoria
                      terceraImagenDeLaCategoria {
                          title
                          file {
                              url
                          }
                      }
                  }
                  redesSocialesEssity{
                    nombreDeLaCategoria
                    redireccionDeLaCategoria
                    terceraImagenDeLaCategoria{
                      title
                      file{
                        url
                      }
                    }
                  }
              }
          }
      `
    )
    const contentInfo = contentfulExtranetTransversal

    const [isSuscriptionOpen, setSuscriptionOpen] = useState(false)
    // const [activeCheckboxSuscription, setActiveCheckboxSuscription] = useState(false)

    const options1 = {
        renderNode: {
            [BLOCKS.HEADING_2]: (node, next) => <h3 className="f-section-contact-title"><i
                className="f-icon-title fal fa-at" />
                {next}</h3>,
            [BLOCKS.PARAGRAPH]: (node, next) => "",
            [BLOCKS.HEADING_5]: (node, next) => "",
            [BLOCKS.HEADING_6]: (node, next) => ""
        }
    }
    const options1B = {
        renderNode: {
            [BLOCKS.HEADING_2]: (node, next) => "",
            [BLOCKS.PARAGRAPH]: (node, next) => <p className="f-section-contact-description">{next}</p>,
            [BLOCKS.HEADING_5]: (node, next) => "",
            [BLOCKS.HEADING_6]: (node, next) => ""
        }
    }
    const options2 = {
        renderNode: {
            [BLOCKS.HEADING_2]: (node, next) => <h3 className="f-section-contact-title"><i
                className="f-icon-title fal fa-envelope" />
                {next}</h3>,
            [BLOCKS.PARAGRAPH]: (node, next) => <p className="f-section-contact-description">{next}</p>
        }
    }
    const options3 = {
        renderNode: {
            [BLOCKS.HEADING_2]: (node, next) => <h3 className="f-section-contact-title"><i
                className="f-icon-title fal fa-envelope" />

                {next}</h3>,
            [BLOCKS.PARAGRAPH]: (node, next) => <p className="f-section-contact-description">{next}</p>
        }
    }
    const options4 = {
        renderNode: {
            [BLOCKS.HEADING_2]: (node, next) => "",
            [BLOCKS.PARAGRAPH]: (node, next) => "",
            [BLOCKS.HEADING_5]: (node, next) => "",
            [BLOCKS.HEADING_6]: (node, next) => <p className="f-section-contact-description">{next}</p>
        }
    }
    const options5 = {
        renderNode: {
            [BLOCKS.HEADING_2]: (node, next) => "",
            [BLOCKS.PARAGRAPH]: (node, next) => "",
            [BLOCKS.HEADING_5]: (node, next) => <p className="f-section-contact-description">{next}</p>,
            [BLOCKS.HEADING_6]: (node, next) => ""
        }
    }

    const { redesSociales } = contentfulExtranetTransversal
    const { redesSocialesEssity } = contentfulExtranetTransversal
    const { logo,logoEssity } = contentfulExtranetTransversal


    const datalayerRedes = (nameRedes) => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: "ga_event",
            category: "Footer",
            action: "Click :: Redes",
            label: nameRedes,
            value: "",
        })
    }



    return (
        <div className={"f-section-contact-container " + (!isSuscriptionOpen ? "f-section-contact-container--hidde" : '')}>
            <div className="f-section-contact">
                <div className="f-social-container f-section-contact-element">
                    <div className="f-section-contact-info">
                        {documentToReactComponents(JSON.parse(contentInfo.informacionSiguenos.raw), options1)}
                            <img
                                src={logoEssity[0].file.url}
                                className="f-social-mark-logo"
                                alt="Logo Essity"
                            />
                    </div>
                    {documentToReactComponents(JSON.parse(contentInfo.informacionSiguenos.raw), options1B)}
                    {documentToReactComponents(JSON.parse(contentInfo.informacionSiguenos.raw), options4)}
                    <div className="f-social-icons-container">

                        {redesSociales.map((data, index) => {
                            return (
                                <div className="f-social-icon-container" key={index}>
                                    <a className={data.nombreDeLaCategoria?.toLowerCase()}
                                        href={data.redireccionDeLaCategoria} target='_blank'
                                        onClick={()=>datalayerRedes(data.nombreDeLaCategoria?.toLowerCase())}
                                        rel="noopener noreferrer" target="_blank">
                                        <img alt="Gatsby" src={data.terceraImagenDeLaCategoria && data.terceraImagenDeLaCategoria[0]?.file?.url} />
                                    </a>
                                </div>

                            )
                        })}
                    </div>
                    {documentToReactComponents(JSON.parse(contentInfo.informacionSiguenos.raw), options5)}
                    <div className="f-social-icons-container">

                        {redesSocialesEssity.map((data, index) => {
                            return (
                                <div className="f-social-icon-container" key={index}>
                                    <a className={data.nombreDeLaCategoria?.toLowerCase()}
                                        href={data.redireccionDeLaCategoria} target='_blank'
                                        onClick={()=>datalayerRedes(data.nombreDeLaCategoria?.toLowerCase())}
                                        rel="noopener noreferrer" target="_blank">
                                        <img alt="Gatsby" src={data.terceraImagenDeLaCategoria && data.terceraImagenDeLaCategoria[0]?.file?.url} />
                                    </a>
                                </div>

                            )
                        })}
                    </div>
                </div>
                <div className="f-spacer" />
                <div className="f-contanct-container f-section-contact-element">
                    {documentToReactComponents(JSON.parse(contentInfo.informacionContactanos.raw), options2)}

                    <Button
                        title={contentInfo.ctaContactanos}
                        color='blue'
                        size="small"
                        onClick={() => {
                            window.open(siteUrl + '/' + contentInfo.ctaRedireccionContactanos, "_self")
                            window.dataLayer = window.dataLayer || []
                            window.dataLayer.push({
                              event: "ga_event",
                              category: "Footer",
                              action: "Click :: Conversa con nosotros",
                              label: "Contáctanos",
                              value: "",
                            })


                        }}
                    />
                </div>
                <div className="f-spacer" />
                <div className="f-contanct-suscribe f-section-contact-element">
                    {documentToReactComponents(JSON.parse(contentInfo.informacionBoletin.raw), options3)}

                    <Button
                        title={contentInfo.ctaBoletin}
                        color='blue'
                        size="small"
                        onClick={() => {
                            setSuscriptionOpen(!isSuscriptionOpen)
                            window.dataLayer = window.dataLayer || []
                            window.dataLayer.push({
                              event: "ga_event",
                              category: "Footer",
                              action: "Click :: Nuestro Boletín",
                              label: "Suscribirme",
                              value: "",
                            })
                        }}
                    />
                </div>

            </div>

            <div className={`f-subscription ${isSuscriptionOpen ? "show" : ""}`} >
                <h4 className="f-subscription__title">Suscríbete a nuestro boletín</h4>
                <p className="f-subscription__description">Si quieres seguir conectado con nosotros, suscríbete al boletín de Grupo Familia®
                y entérate a tiempo de las novedades.</p>

                <iframe id="iFrame1" className="f-subscription__iframe" width="100%" src="https://cloud.digital.grupofamilia.com/grupoFamilia_form" frameBorder="0" allowFullScreen="" scrolling="no" title="Form" />

                {/* <form className="f-subscription-form"  >
                    <Input
                        label='Nombres y apellidos*'
                        onChange={e => { }}
                    />
                    <Input
                        label='Empresa*'
                        onChange={e => { }}
                    />
                    <Input
                        label='País*'
                        onChange={e => { }}
                    />
                    <Input
                        label='Correo electrónico*'
                        onChange={e => { }}

                    />
                    <div className="f-subscription-form__send">
                        <InputCheck
                            label={<p>Acepto <a className="f-terms" target="_blank" href={`${siteUrl}/terminos-y-condiciones/`}>Términos y Condiciones</a></p>}
                            active={activeCheckboxSuscription}
                            setActive={setActiveCheckboxSuscription}
                        />
                        <Button
                            title="Enviar"
                            color='blue'
                            size="medium"
                        />
                    </div>
                </form> */}
            </div>

        </div >
    )
}
export default SectionContact
