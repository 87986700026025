import React from "react"
import "./arrows-slider.scss"

const ArrowsSlider = props => {
	const { direction, banner, onClick, customOnClick } = props

	return (
		<button
			className={`${banner ? `f-btn-slider-banner ${direction}` : `f-btn-slider ${direction}`}`}
			onClick={()=>{
				onClick()
				customOnClick()
			}}>
			<div>
				<i className={'fas fa-chevron-' + direction}></i>
			</div>
		</button>)
}

export default ArrowsSlider
